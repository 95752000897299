jQuery(document).ready(function ($) {
    if ($('#sign_up_form_two').length) {
        $('.loading_icon').addClass('loading');
        var app = new Vue({
            el: '#sign_up_form_two',
            data: {
                form: {},
                introductionProvider: false,
                businessLineOptions: Array(),
                errors: {
                    msg: [],
                    bool: {}
                },
                businessLine: '',
                businessLineInsurer: '',
                businessLineYears: '',
                roi: false,
                currentDate = new Date(),
                monthly: currentDate.getMonth(),
            },
            computed: {
                mgaMembership: function () {
                    if (this.form.companyData.membershipType === 'MGA Member') {
                        return true;
                    } else {
                        return false;
                    }
                },
                supplierMembership: function () {
                    if (this.form.companyData.membershipType === 'Supplier') {
                        return true;
                    } else {
                        return false;
                    }
                },
                marketPractitionerMembership: function () {
                    if (this.form.companyData.membershipType === 'Market Practitioner') {
                        return true;
                    } else {
                        return false;
                    }
                },
                vailidBusinessLine: function () {
                    if (this.businessLine !== '') {
                        return true;
                    }

                    return false;
                },
                billingCountry: function () {
                    if (this.form.companyData.paymentcountry == 'IR') {
                        this.form.companyData.paymentMethod = 'annually'
                        this.form.member.currencyType = "€"
                        this.roi = true;
                        return true;
                    }

                    return false;
                }
            },
            watch: {
                'form.companyData.companyName': function () {
                    this.form.companyData.companyName = this.form.companyData.companyName.replace(/\\/g, "");
                },
                'form.member.gwpAfrica': function () {
                    this.form.member.gwpAfrica = this.form.member.gwpAfrica.replace(/[^0-9]/g, '')
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                },
                'form.member.gwpAsia': function () {
                    this.form.member.gwpAsia = this.form.member.gwpAsia.replace(/[^0-9]/g, '')
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                },
                'form.member.gwpEurope': function () {
                    this.form.member.gwpEurope = this.form.member.gwpEurope.replace(/[^0-9]/g, '')
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                },
                'form.member.gwpNorthAmerica': function () {
                    this.form.member.gwpNorthAmerica = this.form.member.gwpNorthAmerica.replace(/[^0-9]/g, '')
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                },
                'form.member.gwpProi': function () {
                    this.form.member.gwpProi = this.form.member.gwpProi.replace(/[^0-9]/g, '')
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                },
                'form.member.gwpUk': function () {
                    this.form.member.gwpUk = this.form.member.gwpUk.replace(/[^0-9]/g, '')
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                },
                'form.member.gwpUsa': function () {
                    this.form.member.gwpUsa = this.form.member.gwpUsa.replace(/[^0-9]/g, '')
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                },
                //add space to postcode automatically
                'form.companyData.postcode': function () {
                    if (this.form.companyData.paymentcountry != "IR") {

                        let postcodesplit = this.form.companyData.postcode.split('');
                        let addSpace = ""
                        for (character in postcodesplit) {
                            if (postcodesplit[character] == ' ') {
                                postcodesplit.splice(character, 1);
                            }
                        }
                        if (postcodesplit.length > 5) {
                            if (postcodesplit.length == 6) {
                                for (character in postcodesplit) {
                                    if (character == 3) {
                                        addSpace += ' ' + postcodesplit[character]
                                    } else {
                                        addSpace += postcodesplit[character]
                                    }
                                }
                            } else {
                                for (character in postcodesplit) {
                                    if (character == 4) {
                                        addSpace += ' ' + postcodesplit[character]
                                    } else {
                                        addSpace += postcodesplit[character]
                                    }
                                }
                            }
                            this.form.companyData.postcode = addSpace;
                        }
                    }

                }
            },
            created() {
                this.getPreFilledData();
                if (this.monthly < 6 || this.monthly > 7) {
                    if (new Date().getMonth() !== 7) {
                        $("#patment-select").html(`<option value="">Select a Payment Method</option>
                    <option value="monthly">Monthly - Direct Debit</option>
                    <option value="annually">Annually - BAC's</option>
                    <option value="annually-card">Annually - Card</option>`)
                    } else {
                        $("#patment-select").html(`<option value="">Select a Payment Method</option>
                    <option value="annually">Annually - BAC's</option>
                    <option value="annually-card">Annually - Card</option>`)
                    }
                }
            },
            methods: {
                getPreFilledData() {
                    $.ajax({
                        url: ajax_url,
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            action: 'preFilledData'
                        },
                        error: function (res) {
                            console.log(res);
                        },
                        success: (res) => {
                            console.log(res);
                            this.form = res;
                            $('.loading_icon').removeClass('loading');
                            if (res.member.principal !== '') {
                                this.authorisedRep = true;
                            }

                            if (res.member.auditAmount !== '') {
                                this.audited = true;
                            }

                            if (res.member.introduction !== '') {
                                this.introductionProvider = true;
                            }

                            if (res.companyData.membershipType === 'MGA Member') {
                                this.getLinesOfBusiness();
                            }
                        }
                    });
                },

                getLinesOfBusiness() {
                    $.ajax({
                        url: '/wp-json/custom-routes/v1/product-types',
                        error: (res) => {
                            console.log(res);
                        },
                        success: (res) => {
                            this.businessLineOptions = res;
                            // this.businessLineOptions = res.map(function(item) {
                            //     return item.product_name;
                            // });
                        }
                    });
                },
                addBusinessLine() {
                    // if (this.businessLine !== '' && this.businessLineInsurer && this.businessLineYears) {
                    if (this.businessLine !== '') {
                        let selected = this.businessLineOptions[this.businessLine];

                        /* 
                            filtering the selected lines of business to see if the selected  value is already value already 
                            in the array. If a match is found we return it to the result array
                        */
                        // let result = this.form.member.linesOfBusiness.filter(function(item) {
                        //     if (item.name === selected.product_name) {
                        //         return item;
                        //     }
                        // });

                        // if no match was found we add it to the array
                        // if ( result.length === 0 ) {
                        this.form.member.linesOfBusiness.push({
                            id: selected.product_id,
                            name: selected.product_name,
                            insurer: this.businessLineInsurer,
                            years: this.businessLineYears
                        });

                        // clearing input
                        // this.businessLine = "";
                        this.businessLineInsurer = "";
                        this.businessLineYears = "";
                        // }
                    }
                },

                addDirector(event) {
                    let value = $('.director-input').val();
                    if (!this.form.member.directors.includes(value)) {
                        this.form.member.directors.push(value);
                    }
                },
                removeDirectors(index) {
                    this.form.member.directors.splice(index, 1);
                },
                removeBusinessLine(index) {
                    this.form.member.linesOfBusiness.splice(index, 1);
                },
                addOtherUnderwitingSystem(event) {
                    let value = $('.other-underwriting-input').val();
                    if (!this.form.member.underwritingSystem.includes(value)) {
                        this.form.member.underwritingSystem.push(value);
                    }
                },
                addUnderwitingSystem(event) {
                    let value = event.target.value;

                    if (!this.form.member.underwritingSystem.includes(value)) {
                        this.form.member.underwritingSystem.push(value);
                    }
                },
                removeUnderwritingSystem(index) {
                    this.form.member.underwritingSystem.splice(index, 1);
                },
                saveProgress() {
                    $('#save-msg').hide();

                    $.ajax({
                        url: ajax_url,
                        type: 'POST',
                        // dataType: 'json',
                        data: {
                            formData: this.form,
                            action: 'saveFormData'
                        },
                        error: function (res) {
                            console.log(res);
                        },
                        success: (res) => {
                            console.log(res);
                            $('#save-msg').show();
                        }
                    });
                },
                submitForm() {
                    this.validateForm();
                    if (this.errors.msg.length <= 0) {
                        $.ajax({
                            url: ajax_url,
                            type: 'POST',
                            dataType: 'json',
                            data: {
                                formData: this.form,
                                action: 'submitSignUpForm'
                            },
                            error: function (res) {
                                console.log(res);
                            },
                            success: (res) => {
                                window.location.href = res.redirect;
                                // console.log(res);
                            }
                        });
                    } else {
                        this.scrollToErrors();
                    }
                },
                validateForm() {
                    this.errors.msg = [];
                    this.errors.bool = {};

                    if (!this.form.userMeta.firstName) {
                        this.errors.msg.push('First Name required.');
                        this.errors.bool['firstName'] = true;
                    } else if (!this.validateName(this.form.userMeta.firstName)) {
                        this.errors.msg.push('First Name must only contain letters');
                        this.errors.bool['firstName'] = true;
                    }

                    if (!this.form.userMeta.lastName) {
                        this.errors.msg.push('Last Name required.');
                        this.errors.bool['lastName'] = true;
                    } else if (!this.validateName(this.form.userMeta.lastName)) {
                        this.errors.msg.push('Last Name must only contain letters');
                        this.errors.bool['lastName'] = true;
                    }


                    if (!this.form.userMeta.position) {
                        this.errors.msg.push('Position required.');
                        this.errors.bool['position'] = true;
                    } else if (!this.validateName(this.form.userMeta.position)) {
                        this.errors.msg.push('Please enter a valid position You are limited to 100 characters');
                        this.errors.bool['position'] = true;
                    }


                    this.form.userMeta.userPhoneNumber = this.form.userMeta.userPhoneNumber.replace(/[^0-9]/g, '');
                    if (!this.form.userMeta.userPhoneNumber) {
                        this.errors.msg.push('Telephone Number required.');
                        this.errors.bool['userPhoneNumber'] = true;
                    } else if (!this.validateGenericPhoneNumber(this.form.userMeta.userPhoneNumber)) {
                        this.errors.msg.push('Please enter a valid Phone Number');
                        this.errors.bool['userPhoneNumber'] = true;
                    }

                    if (!this.form.companyData.companyName) {
                        this.errors.msg.push('Organisation Name required.');
                        this.errors.bool['companyName'] = true;
                    } else if (!this.validatecompany(this.form.companyData.companyName)) {
                        this.errors.msg.push('Please enter a valid company name');
                        this.errors.bool['company'] = true;
                    }

                    if (!this.form.companyData.addressLineOne) {
                        this.errors.msg.push('Address Line 1 required.');
                        this.errors.bool['addressLineOne'] = true;
                    } else if (!this.validateGenericText(this.form.companyData.addressLineOne)) {
                        this.errors.msg.push('please enter a valid address. You are limited to 100 characters');
                        this.errors.bool['addressLineOne'] = true;
                    }
                    if (!this.validateGenericTextNotRequired(this.form.companyData.addressLineTwo)) {
                        this.errors.msg.push('please enter a valid address. You are limited to 100 characters');
                        this.errors.bool['addressLineTwo'] = true;
                    }
                    if (!this.validateGenericTextNotRequired(this.form.companyData.addressLineThree)) {
                        this.errors.msg.push('please enter a valid address. You are limited to 100 characters');
                        this.errors.bool['addressLineThree'] = true;
                    }


                    if (!this.form.companyData.town) {
                        this.errors.msg.push('Town required.');
                        this.errors.bool['town'] = true;
                    } else if (!this.validatecompany(this.form.companyData.town)) {
                        this.errors.msg.push('Valid town name required');
                        this.errors.bool['town'] = true;
                    }

                    if (!this.form.companyData.county) {
                        this.errors.msg.push('County required.');
                        this.errors.bool['county'] = true;
                    } else if (!this.validatecompany(this.form.companyData.county)) {
                        this.errors.msg.push('Valid County required');
                        this.errors.bool['county'] = true;
                    }


                    if (!this.form.companyData.postcode) {
                        this.errors.msg.push('Postcode required');
                        this.errors.bool['postcode'] = true;
                    }
                    if (this.form.companyData.paymentcountry == "IR") {
                        if (!this.validateIrPostcode(this.form.companyData.postcode)) {
                            this.errors.msg.push('Valid postcode required in the format EC3R 7AA');
                            this.errors.bool['postcode'] = true;
                        }
                    } else {
                        if (!this.validatePostcode(this.form.companyData.postcode)) {
                            this.errors.msg.push('Valid postcode required in the format EC3R 7AA');
                            this.errors.bool['postcode'] = true;
                        }
                    }

                    if (!this.form.companyData.country) {
                        this.errors.msg.push('Country Name required.');
                        this.errors.bool['country'] = true;
                    } else if (!this.validatecompany(this.form.companyData.country)) {
                        this.errors.msg.push('Valid country required');
                        this.errors.bool['country'] = true;
                    }

                    if (this.form.companyData.organisationWebsite != "" && !this.validateURL(this.form.companyData.organisationWebsite)) {
                        this.errors.msg.push('Please enter a valid website or leave this field blank. You are limited to 100 characters');
                        this.errors.bool['organisationWebsite'] = true;
                    }
                    this.form.companyData.companyPhoneNumber = this.form.companyData.companyPhoneNumber.replace(/[^0-9]/g, '');
                    if (!this.form.companyData.companyPhoneNumber) {
                        this.errors.msg.push('Company Telephone Number required.');
                        this.errors.bool['companyPhoneNumber'] = true;
                    } else if (!this.validateGenericPhoneNumber(this.form.companyData.companyPhoneNumber)) {
                        this.errors.msg.push('Please enter a valid Company Telephone Number');
                        this.errors.bool['companyPhoneNumber'] = true;
                    }


                    // if company has another registered address
                    if (this.form.companyData.differentRegAddress === '1') {
                        if (!this.form.companyData.regAddressLineOne) {
                            this.errors.msg.push('Company Registered Address Line 1 required.');
                            this.errors.bool['regAddressLineOne'] = true;
                        } else if (!this.validateGenericText(this.form.companyData.regAddressLineOne)) {
                            this.errors.msg.push('please enter a valid address. You are limited to 100 characters');
                            this.errors.bool['regAddressLineOne'] = true;
                        }
                        if (!this.validateGenericTextNotRequired(this.form.companyData.regAddressLineTwo)) {
                            this.errors.msg.push('please enter a valid address. You are limited to 100 characters');
                            this.errors.bool['regAddressLineTwo'] = true;
                        }
                        if (!this.validateGenericTextNotRequired(this.form.companyData.regAddressLineThree)) {
                            this.errors.msg.push('please enter a valid address. You are limited to 100 characters');
                            this.errors.bool['regAddressLineThree'] = true;
                        }


                        if (!this.form.companyData.regTown) {
                            this.errors.msg.push('Company Registered Town required.');
                            this.errors.bool['regTown'] = true;
                        } else if (!this.validatecompany(this.form.companyData.regTown)) {
                            this.errors.msg.push('Valid town name required');
                            this.errors.bool['regTown'] = true;
                        }

                        if (!this.form.companyData.regCounty) {
                            this.errors.msg.push('Company Registered County required.');
                            this.errors.bool['regCounty'] = true;
                        } else if (!this.validatecompany(this.form.companyData.regCounty)) {
                            this.errors.msg.push('Valid County required');
                            this.errors.bool['regCounty'] = true;
                        }

                        if (!this.form.companyData.regPostcode) {
                            this.errors.msg.push('Company Registered Postcode required.');
                            this.errors.bool['regPostcode'] = true;
                        } else if (!this.validateGenericText(this.form.companyData.regPostcode)) {
                            this.errors.msg.push('please enter a valid Postcode');
                            this.errors.bool['regPostcode'] = true;
                        }

                        if (!this.form.companyData.regCountry) {
                            this.errors.msg.push('Company Registered Country Name required.');
                            this.errors.bool['regCountry'] = true;
                        } else if (!this.validatecompany(this.form.companyData.regCountry)) {
                            this.errors.msg.push('Valid country required');
                            this.errors.bool['regCountry'] = true;
                        }
                    }

                    if (this.mgaMembership) {
                        if (this.roi == false) {

                            if (!this.form.member.gwpUk) {
                                this.errors.msg.push('GWPUK required.');
                                this.errors.bool['GWP'] = true;
                            } else if (!this.validateGenericText(this.form.member.gwpUk)) {
                                this.errors.msg.push('please enter a valid GWP.');
                                this.errors.bool['GWP'] = true;
                            }
                        }
                        if (this.roi) {
                            if (!this.form.member.gwpProi) {
                                this.errors.msg.push('GWPROI required.');
                                this.errors.bool['GWPROI'] = true;
                            } else if (!this.validateGenericText(this.form.member.gwpProi)) {
                                this.errors.msg.push('please enter a valid GWPROI.');
                                this.errors.bool['GWPROI'] = true;
                            }
                        }
                        if (!this.form.member.tobaUpdate) {
                            this.errors.msg.push('How often do you update your TOBA required.');
                            this.errors.bool['tobaUpdate'] = true;
                        } else if (!this.validateGenericText(this.form.companyData.tobaUpdate)) {
                            this.errors.msg.push('please enter a valid value for How often do you update your TOBA.');
                            this.errors.bool['tobaUpdate'] = true;
                        }


                        if (this.form.member.tobaOutsource == "") {
                            this.errors.msg.push('Do you outsource your TOBA required.');
                            // this.errors.bool['tobaUpdate'] = true;
                        }
                        // if (this.form.member.gwpAfrica !="" && !this.validateGenericTextNotRequired(this.form.member.gwpAfrica)) {
                        //     this.errors.msg.push('Please enter a valid gwpAfrica or leave this field blank. You are limited to 100 characters');
                        //     this.errors.bool['gwpAfrica'] = true;
                        // }
                        // if (this.form.member.gwpAsia !="" && !this.validateGenericTextNotRequired(this.form.member.gwpAsia)) {
                        //     this.errors.msg.push('Please enter a valid gwpAsia or leave this field blank. You are limited to 100 characters');
                        //     this.errors.bool['gwpAsia'] = true;
                        // }
                        // if (this.form.member.gwpEurope !="" && !this.validateGenericTextNotRequired(this.form.member.gwpEurope)) {
                        //     this.errors.msg.push('Please enter a valid gwpEurope or leave this field blank. You are limited to 100 characters');
                        //     this.errors.bool['gwpEurope'] = true;
                        // }
                        // if (this.form.member.gwpNorthAmerica !="" && !this.validateGenericTextNotRequired(this.form.member.gwpNorthAmerica)) {
                        //     this.errors.msg.push('Please enter a valid gwpNorthAmerica or leave this field blank. You are limited to 100 characters');
                        //     this.errors.bool['gwpNorthAmerica'] = true;
                        // }

                        // if (this.form.member.gwpUsa !="" && !this.validateGenericTextNotRequired(this.form.member.gwpUsa)) {
                        //     this.errors.msg.push('Please enter a valid gwpUsa or leave this field blank. You are limited to 100 characters');
                        //     this.errors.bool['gwpUsa'] = true;
                        // }


                        if (!this.form.companyData.dateEstablished) {
                            this.errors.msg.push('Date Established required.');
                            this.errors.bool['dateEstablished'] = true;
                        } else if (!this.validateGenericText(this.form.companyData.dateEstablished)) {
                            this.errors.msg.push('please enter a valid Date.');
                            this.errors.bool['dateEstablished'] = true;
                        }

                        if (!this.form.member.registeredNum) {
                            this.errors.msg.push('Registered Organisation Number required.');
                            this.errors.bool['regNum'] = true;
                        } else if (!this.validateGenericText(this.form.member.registeredNum)) {
                            this.errors.msg.push('please enter a valid Organisation Number');
                            this.errors.bool['regNum'] = true;
                        }

                        if (this.form.member.registeredVAT) {

                            if (!this.validateGenericText(this.form.member.registeredVAT)) {
                                this.errors.msg.push('please enter a valid VAT Number');
                                this.errors.bool['vatNum'] = true;
                            }
                        }

                        if (!this.form.member.legalStatus) {
                            this.errors.msg.push('Organisation\'s Legal Status required.');
                            this.errors.bool['legalStatus'] = true;
                        }

                        if (!this.form.member.ownership) {
                            this.errors.msg.push('Organisation\'s Ownership required.');
                            this.errors.bool['organisationOwnership'] = true;
                        }

                        if (!this.form.member.directors) {
                            this.errors.msg.push('Name of Directors required.');
                            this.errors.bool['directorsNames'] = true;
                        } else if (!this.validateGenericText255(this.form.member.directors)) {
                            this.errors.msg.push('please enter a valid Directors');
                            this.errors.bool['directorsNames'] = true;
                        }

                        if (!this.form.member.activity) {
                            this.errors.msg.push('Type of Activity required.');
                            this.errors.bool['activityType'] = true;
                        }

                        if (!this.form.member.fcaRef) {
                            this.errors.msg.push('FCA firm reference number required.');
                            this.errors.bool['fcaRef'] = true;
                        } else if (!this.validateGenericText(this.form.member.fcaRef)) {
                            this.errors.msg.push('please enter a valid reference number');
                            this.errors.bool['fcaRef'] = true;
                        }

                        if (!this.form.member.fiduciaryDuty) {
                            this.errors.msg.push('Who do you owe you fiduciary duty to? required.');
                            this.errors.bool['fiduciaryDuty'] = true;
                        } else if (!this.validatecompany(this.form.member.fiduciaryDuty)) {
                            this.errors.msg.push('Who do you owe you fiduciary duty to? required. You are limited to 100 characters');
                            this.errors.bool['fiduciaryDuty'] = true;
                        }

                        if (this.form.member.authorisedRep === '1') {
                            if (!this.form.member.principal) {
                                this.errors.msg.push('What is the name of your principal? required.');
                                this.errors.bool['principal'] = true;
                            } else if (!this.validateName(this.form.member.principal)) {
                                this.errors.msg.push('What is the name of your principal? required. You are limited to 100 characters');
                                this.errors.bool['principal'] = true;
                            }
                        }

                        if (!this.form.member.distributionModel) {
                            this.errors.msg.push('Distribution Model required.');
                            this.errors.bool['distributionModel'] = true;
                        }

                        if (!this.form.member.producingBrokers) {
                            this.errors.msg.push('How many producing brokers do you have? required.');
                            this.errors.bool['producingBrokers'] = true;
                        } else if (!this.validateGenericNumber(this.form.member.producingBrokers)) {
                            this.errors.msg.push('Please enter a valid number of producing brokers');
                            this.errors.bool['producingBrokers'] = true;
                        }
                        if (!this.form.member.policyAmount) {
                            this.errors.msg.push('How many policies do you write? required.');
                            this.errors.bool['policyAmount'] = true;
                        } else if (!this.validateGenericNumber(this.form.member.policyAmount)) {
                            this.errors.msg.push('Please enter a valid number of how many policies you write');
                            this.errors.bool['policyAmount'] = true;
                        }

                        if (!this.form.member.premiumPerPolicy) {
                            this.errors.msg.push('Average Premium Per Policy? required.');
                            this.errors.bool['premiumPerPolicy'] = true;
                        } else if (!this.validateGenericNumber(this.form.member.premiumPerPolicy)) {
                            this.errors.msg.push('Please enter a valid number of Average Premium Per Policy');
                            this.errors.bool['premiumPerPolicy'] = true;
                        }

                        if (!this.form.member.audited) {
                            this.errors.msg.push('When were you last audited? required.');
                            this.errors.bool['audited'] = true;
                        } else if (!this.validateGenericText(this.form.member.audited)) {
                            this.errors.msg.push('Please enter a valid number for When were you last audited');
                            this.errors.bool['audited'] = true;
                        }

                        if (this.form.member.linesOfBusiness.length <= 0) {
                            this.errors.msg.push('Lines of Business required.');
                            this.errors.bool['linesOfBus'] = true;
                        }

                        if (!this.form.member.directorsAmount) {
                            this.errors.msg.push('How many directors? required.');
                            this.errors.bool['directorsAmount'] = true;
                        } else if (!this.validateGenericNumber(this.form.member.directorsAmount)) {
                            this.errors.msg.push('Please enter a valid number for How many directors?');
                            this.errors.bool['directorsAmount'] = true;
                        }

                        if (!this.form.member.underwriters) {
                            this.errors.msg.push('How many underwriters? required.');
                            this.errors.bool['underwriters'] = true;
                        } else if (!this.validateGenericNumber(this.form.member.underwriters)) {
                            this.errors.msg.push('Please enter a valid number for How many underwriters?');
                            this.errors.bool['underwriters'] = true;
                        }

                        if (!this.form.member.claimsStaff) {
                            this.errors.msg.push('How many claims staff? required.');
                            this.errors.bool['claimsStaff'] = true;
                        } else if (!this.validateGenericNumber(this.form.member.claimsStaff)) {
                            this.errors.msg.push('Please enter a valid number for How many claims staff?');
                            this.errors.bool['claimsStaff'] = true;
                        }

                        if (!this.form.member.technicalStaff) {
                            this.errors.msg.push('How many technical IT system related staff? required.');
                            this.errors.bool['itStaff'] = true;
                        } else if (!this.validateGenericNumber(this.form.member.technicalStaff)) {
                            this.errors.msg.push('Please enter a valid number for How many technical IT system related staff?');
                            this.errors.bool['technicalStaff'] = true;
                        }

                        if (!this.form.member.totalStaff) {
                            this.errors.msg.push('Total number of staff including Directors? required.');
                            this.errors.bool['totalStaff'] = true;
                        } else if (!this.validateGenericNumber(this.form.member.totalStaff)) {
                            this.errors.msg.push('Please enter a valid number for Total number of staff including Directors?');
                            this.errors.bool['totalStaff'] = true;
                        }


                        if (!this.form.member.fciiQualifiedStaff) {
                            this.errors.msg.push('How many staff are FCII qualified? required.');
                            this.errors.bool['fciiQualifiedStaff'] = true;
                        } else if (!this.validateGenericNumber(this.form.member.fciiQualifiedStaff)) {
                            this.errors.msg.push('Please enter a valid number for How many staff are FCII qualified?');
                            this.errors.bool['fciiQualifiedStaff'] = true;
                        }

                        if (!this.form.member.aciiQualifiedStaff) {
                            this.errors.msg.push('How many staff are ACII qualified? required.');
                            this.errors.bool['aciiQualifiedStaff'] = true;
                        } else if (!this.validateGenericNumber(this.form.member.aciiQualifiedStaff)) {
                            this.errors.msg.push('Please enter a valid number for How many staff are ACII qualified?');
                            this.errors.bool['aciiQualifiedStaff'] = true;
                        }

                        if (!this.form.member.diplomaStaff) {
                            this.errors.msg.push('How many staff are DIP CII qualified? required.');
                            this.errors.bool['diplomaStaff'] = true;
                        } else if (!this.validateGenericNumber(this.form.member.diplomaStaff)) {
                            this.errors.msg.push('Please enter a valid number for How many staff are DIP CII qualified?');
                            this.errors.bool['diplomaStaff'] = true;
                        }


                        if (!this.form.member.ciiQualifiedStaff) {
                            this.errors.msg.push('How many staff have CERT CII qualifications? required.');
                            this.errors.bool['ciiQualifiedStaff'] = true;
                        } else if (!this.validateGenericNumber(this.form.member.ciiQualifiedStaff)) {
                            this.errors.msg.push('Please enter a valid number for How many staff have CERT CII qualifications?');
                            this.errors.bool['ciiQualifiedStaff'] = true;
                        }

                        if (!this.form.member.ciiLearningStaff) {
                            this.errors.msg.push('How many staff are currently engaging in achieving CII qualification? required.');
                            this.errors.bool['ciiLearningStaff'] = true;
                        } else if (!this.validateGenericNumber(this.form.member.ciiLearningStaff)) {
                            this.errors.msg.push('Please enter a valid number for How many staff are currently engaging in achieving CII qualification?');
                            this.errors.bool['ciiLearningStaff'] = true;
                        }

                        if (!this.form.member.yourBroker) {
                            this.errors.msg.push('Please advise which broker you use required.');
                            this.errors.bool['yourBroker'] = true;
                        } else if (!this.validateGenericText(this.form.member.yourBroker)) {
                            this.errors.msg.push('Please enter a valid value for Please advise which broker you use You are limited to 100 characters');
                            this.errors.bool['yourBroker'] = true;
                        }


                        if (!this.form.member.yourInsurer) {
                            this.errors.msg.push('Please advise which insurer you use required.');
                            this.errors.bool['yourInsurer'] = true;
                        } else if (!this.validateGenericText(this.form.member.yourInsurer)) {
                            this.errors.msg.push('Please enter a valid value for Please advise which insurer you use You are limited to 100 characters');
                            this.errors.bool['yourInsurer'] = true;
                        }


                        if (!this.form.member.policyLimit) {
                            this.errors.msg.push('What limit is on your policy? required.');
                            this.errors.bool['policyLimit'] = true;
                        } else if (!this.validateGenericText(this.form.member.policyLimit)) {
                            this.errors.msg.push('Please enter a valid value for What limit is on your policy? You are limited to 100 characters');
                            this.errors.bool['policyLimit'] = true;
                        }



                        if (!this.form.member.complianceEmail) {
                            this.errors.msg.push('Email address of compliance forum contact required.');
                            this.errors.bool['complianceEmail'] = true;
                        } else if (!this.validEmail(this.form.member.complianceEmail)) {
                            this.errors.msg.push('Valid Email address of compliance forum contact required.');
                            this.errors.bool['complianceEmail'] = true;
                        }


                        if (!this.form.member.itEmail) {
                            this.errors.msg.push('Email address of IT forum contact required.');
                            this.errors.bool['itEmail'] = true;
                        } else if (!this.validEmail(this.form.member.itEmail)) {
                            this.errors.msg.push('Valid Email address of IT forum contact required.');
                            this.errors.bool['itEmail'] = true;
                        }

                        if (!this.form.member.claimsEmail) {
                            this.errors.msg.push('Email address of claims forum contact required.');
                            this.errors.bool['claimsEmail'] = true;
                        } else if (!this.validEmail(this.form.member.claimsEmail)) {
                            this.errors.msg.push('Valid Email address of claims forum contact required.');
                            this.errors.bool['claimsEmail'] = true;
                        }

                        if (!this.form.member.financeOperationsEmail) {
                            this.errors.msg.push('Email address of finance/operations forum contact required.');
                            this.errors.bool['financeOperationsEmail'] = true;
                        } else if (!this.validEmail(this.form.member.financeOperationsEmail)) {
                            this.errors.msg.push('Valid Email address of finance/operations forum contact required.');
                            this.errors.bool['financeOperationsEmail'] = true;
                        }


                        if (!this.form.member.mgaaLloydsMarketEmail) {
                            this.errors.msg.push('Email address of MGAA Lloyds market forum contact required.');
                            this.errors.bool['mgaaLloydsMarketEmail'] = true;
                        } else if (!this.validEmail(this.form.member.mgaaLloydsMarketEmail)) {
                            this.errors.msg.push('Valid Email address of MGAA Lloyds market forum contact required.');
                            this.errors.bool['mgaaLloydsMarketEmail'] = true;
                        }
                        if (this.form.member.mgaaNextGenInterested == 1) {

                            if (!this.form.member.mgaaNextGenGroupEmail) {
                                this.errors.msg.push('MGAA Next Gen Group contact required.');
                                this.errors.bool['mgaaNextGenGroupEmail'] = true;
                            } else if (!this.validEmail(this.form.member.mgaaNextGenGroupEmail)) {
                                this.errors.msg.push('Valid MGAA Next Gen Group contact required.');
                                this.errors.bool['mgaaNextGenGroupEmail'] = true;
                            }
                        }


                        if (this.form.member.brexitEmail) {
                            if (!this.validEmail(this.form.member.brexitEmail)) {
                                this.errors.msg.push('Valid Email address of Brexit forum contact required.');
                                this.errors.bool['brexitEmail'] = true;
                            }
                        }

                        if (this.form.member.trainingDevelopmentEmail) {

                            if (!this.validEmail(this.form.member.trainingDevelopmentEmail)) {
                                this.errors.msg.push('Valid Email address of training and development required.');
                                this.errors.bool['trainingDevelopmentEmail'] = true;
                            }
                        }


                        if (this.form.member.developmentLeadEmail) {

                            if (!this.validEmail(this.form.member.developmentLeadEmail)) {
                                this.errors.msg.push('Valid Email address of your Business Development Lead required.');
                                this.errors.bool['developmentLeadEmail'] = true;
                            }
                        }

                        if (!this.form.member.marketingEmail) {
                            if (!this.validEmail(this.form.member.marketingEmail)) {
                                this.errors.msg.push('Valid Email address of Marketing Contact required.');
                                this.errors.bool['marketingEmail'] = true;
                            }
                        }
                        if (!this.form.member.technicalEmail) {
                            this.errors.msg.push('Technical Underwriting forum contact required.');
                            this.errors.bool['technicalEmail'] = true;
                        } else if (!this.validEmail(this.form.member.technicalEmail)) {
                            this.errors.msg.push('Valid Technical Underwriting forum contact required.');
                            this.errors.bool['technicalEmail'] = true;
                        }
                    }

                    if (this.marketPractitionerMembership) {

                        if (!this.validateGenericTextNotRequired(this.form.companyData.bestRating)) {
                            this.errors.msg.push('please enter a valid value for "Please can you provide your S&P/AM Best rating" or leave this field blank. You are limited to 100 characters');
                            this.errors.bool['bestRating'] = true;
                        }

                        if (!this.validateGenericTextNotRequired(this.form.companyData.mgaaEngagement)) {
                            this.errors.msg.push('please enter a valid value for "How do you envisage engaging with the MGAA and it’s Members?" or leave this field blank. You are limited to 100 characters');
                            this.errors.bool['mgaaEngagement'] = true;
                        }
                        if (!this.validateGenericTextNotRequired(this.form.companyData.mgasSupported)) {
                            this.errors.msg.push('please enter a valid value for "Please provide the number of MGAs currently supported by you." or leave this field blank. You are limited to 100 characters');
                            this.errors.bool['mgasSupported'] = true;
                        }
                        if (!this.validateGenericTextNotRequired(this.form.companyData.insuranceCapacity)) {
                            this.errors.msg.push('please enter a valid value for "What covers do you provide capacity for?" or leave this field blank. You are limited to 100 characters');
                            this.errors.bool['insuranceCapacity'] = true;
                        }
                        if (!this.validateGenericTextNotRequired(this.form.companyData.minAnnualPremium)) {
                            this.errors.msg.push('please enter a valid value for "What is the minimum annual premium to be considered for any one arrangement?" or leave this field blank. You are limited to 100 characters');
                            this.errors.bool['minAnnualPremium'] = true;
                        }
                        if (!this.validateGenericTextNotRequired(this.form.companyData.mgaaSupport)) {
                            this.errors.msg.push('please enter a valid value for "What can the MGAA do to help support your MGA/Delegated Binding Authority related strategic goals?" or leave this field blank. You are limited to 100 characters');
                            this.errors.bool['mgaaSupport'] = true;
                        }
                        if (!this.validateGenericTextNotRequired(this.form.companyData.futureInvolvement)) {
                            this.errors.msg.push('please enter a valid value for "What is your future involvement in the MGA sector?" or leave this field blank. You are limited to 100 characters');
                            this.errors.bool['futureInvolvement'] = true;
                        }

                        if (!this.form.member.legalStatus) {
                            this.errors.msg.push('Organisation\'s legal status (eg Ltd, LLP etc) required.');
                            this.errors.bool['legalStatus'] = true;
                        } else if (!this.validateGenericText(this.form.member.legalStatus)) {
                            this.errors.msg.push('Please enter a valid value for Organisation\'s legal status (eg Ltd, LLP etc)');
                            this.errors.bool['legalStatus'] = true;
                        }

                        if (!this.form.member.fcaReference) {
                            this.errors.msg.push('FCA firm reference number required.');
                            this.errors.bool['fcaRef'] = true;
                        } else if (!this.validateGenericText(this.form.member.fcaReference)) {
                            this.errors.msg.push('Please enter a valid value FCA firm reference number required');
                            this.errors.bool['fcaRef'] = true;
                        }

                        if (!this.form.member.delegatedAuthorityYears) {
                            this.errors.msg.push('How many years have you been involved in MGA Delegated Authority business? required.');
                            this.errors.bool['mgaDelegatedAuth'] = true;
                        } else if (!this.validateGenericText(this.form.member.delegatedAuthorityYears)) {
                            this.errors.msg.push('Please enter a valid value How many years have you been involved in MGA Delegated Authority business?');
                            this.errors.bool['mgaDelegatedAuth'] = true;
                        }

                        if (this.form.member.introduction === '1') {
                            if (!this.form.member.introducing) {
                                this.errors.msg.push('Name of MGAs that you can provide an introduction for required.');
                                this.errors.bool['introNames'] = true;
                            } else if (!this.validatecompany(this.form.member.introducing)) {
                                this.errors.msg.push('Please enter a valid value Name of MGAs that you can provide an introduction for required  You are limited to 100 characters');
                                this.errors.bool['introNames'] = true;
                            }
                        }
                    }

                    if (this.supplierMembership) {
                        if (!this.form.companyData.dateEstablished) {
                            this.errors.msg.push('Date Established required.');
                            this.errors.bool['dateEstablished'] = true;
                        } else if (!this.validateGenericText(this.form.companyData.dateEstablished)) {
                            this.errors.msg.push('please enter a valid Date.');
                            this.errors.bool['dateEstablished'] = true;
                        }

                        if (!this.form.member.bespoke) {
                            this.errors.msg.push('Please describe the bespoke products and/or services you wish to promote to the MGAA membership. Required.');
                            this.errors.bool['bespoke'] = true;
                        } else if (!this.validateGenericText1000(this.form.member.bespoke)) {
                            this.errors.msg.push('please enter a valid value for Please describe the bespoke products and/or services you wish to promote to the MGAA membership. You are limited to 1000 characters');
                            this.errors.bool['bespoke'] = true;
                        }

                        if (!this.form.member.benefits) {
                            this.errors.msg.push('How do your Organisation\'s products and/or services specifically benefit MGAs? Required.');
                            this.errors.bool['benefit'] = true;
                        } else if (!this.validateGenericText1000(this.form.member.benefits)) {
                            this.errors.msg.push('please enter a valid value for How do your Organisation\'s products and/or services specifically benefit MGAs? You are limited to 1000 characters');
                            this.errors.bool['benefits'] = true;
                        }

                        if (!this.form.member.mgaPartners) {
                            this.errors.msg.push('Name the MGAs you are currently working with. Required.');
                            this.errors.bool['partners'] = true;
                        } else if (!this.validateGenericText1000(this.form.member.mgaPartners)) {
                            this.errors.msg.push('please enter a valid value for Name the MGAs you are currently working with. You are limited to 1000 characters');
                            this.errors.bool['mgaPartners'] = true;
                        }

                        if (!this.form.member.highlightOne || !this.form.member.highlightTwo || !this.form.member.highlightThree) {
                            this.errors.msg.push('List three highlights of your product and/or services that differentiates you from others in the marketplace. Required.');
                            this.errors.bool['highlight'] = true;
                        }

                        if (!this.validateGenericText1000(this.form.member.highlightOne)) {
                            this.errors.msg.push('please enter a valid value List three highlights of your product and/or services that differentiates you from others in the marketplace. You are limited to 1000 characters');
                            this.errors.bool['highlight'] = true;
                        }

                        if (!this.validateGenericText1000(this.form.member.highlightTwo)) {
                            this.errors.msg.push('please enter a valid value List three highlights of your product and/or services that differentiates you from others in the marketplace. You are limited to 1000 characters');
                            this.errors.bool['highlight'] = true;
                        }

                        if (!this.validateGenericText1000(this.form.member.highlightThree)) {
                            this.errors.msg.push('please enter a valid value List three highlights of your product and/or services that differentiates you from others in the marketplace. You are limited to 1000 characters');
                            this.errors.bool['highlight'] = true;
                        }



                        if (!this.form.member.discounts) {
                            this.errors.msg.push('Identify any preferred or discounted services you would be willing to offer MGAA members. Required.');
                            this.errors.bool['discounts'] = true;
                        } else if (!this.validateGenericText1000(this.form.member.discounts)) {
                            this.errors.msg.push('please enter a valid value for Identify any preferred or discounted services you would be willing to offer MGAA members. You are limited to 1000 characters');
                            this.errors.bool['discounts'] = true;
                        }

                        if (!this.form.member.engagement) {
                            this.errors.msg.push('How do you envisage engaging with the MGAA and it’s Members? Required.');
                            this.errors.bool['engagement'] = true;
                        } else if (!this.validateGenericText1000(this.form.member.engagement)) {
                            this.errors.msg.push('please enter a valid value How do you envisage engaging with the MGAA and it’s Members You are limited to 1000 characters');
                            this.errors.bool['engagement'] = true;
                        }
                    }

                    if (this.form.companyData.diffBillingAddress === '1') {
                        if (!this.form.companyData.billingAddressLineOne) {
                            this.errors.msg.push('Billing Address Line 1 required.');
                            this.errors.bool['billingAddressLineOne'] = true;
                        }

                        if (!this.form.companyData.billingTown) {
                            this.errors.msg.push('Billing Address Town required.');
                            this.errors.bool['billingTown'] = true;
                        }

                        if (!this.form.companyData.billingCounty) {
                            this.errors.msg.push('Billing Address County required.');
                            this.errors.bool['billingCounty'] = true;
                        }

                        if (!this.form.companyData.billingPostcode) {
                            this.errors.msg.push('Billing Address Postcode required.');
                            this.errors.bool['billingPostcode'] = true;
                        }

                        if (!this.form.companyData.billingCountry) {
                            this.errors.msg.push('Billing Address Country Name required.');
                            this.errors.bool['billingCountry'] = true;
                        }
                    }

                    if (this.form.companyData.paymentMethod === '') {
                        this.errors.msg.push('Billing Payment Method required.');
                        this.errors.bool['paymentMethod'] = true;
                    }
                    if (this.form.companyData.paymentMethod == 'monthly') {

                        this.form.companyData.sortcode = this.form.companyData.sortcode.replace(/[^0-9]/g, '');
                        if (!this.validsortcode(this.form.companyData.sortcode)) {
                            if (this.form.companyData.paymentMethod == 'monthly' && this.form.companyData.sortcode != "") {
                                this.errors.msg.push('SortCode is required for Direct Debit Payments.');
                                this.errors.bool['sortcode'] = true;
                            }
                        }

                        if (!this.validaccountnumber(this.form.companyData.accountnumber)) {
                            this.form.companyData.accountnumber = this.form.companyData.accountnumber.replace(/[^0-9]/g, '')
                            if (this.form.companyData.accountnumber != "") {
                                this.errors.msg.push('Account Number is required for Direct Debit Payments.');
                                this.errors.bool['accountnumber'] = true;
                            }
                        }
                    }

                },
                validatecompany(company) {
                    let re = /^[a-zA-Z0-9\s\S\D]{1,60}$/
                    return re.test(company);
                },
                validateGenericText(text) {
                    let re = /^[a-zA-Z0-9'\s\S\D]{1,100}$/
                    return re.test(text);
                },
                validateGenericText1000(text) {
                    let re = /^[a-zA-Z0-9'\s\S\D]{1,1000}$/
                    return re.test(text);
                },
                validateGenericText255(text) {
                    let re = /^[a-zA-Z0-9'\s\S\D]{1,255}$/
                    return re.test(text);
                },
                validateGenericTextNotRequired(text) {
                    let re = /^[a-zA-Z0-9'\s\S\D]{0,100}$/
                    return re.test(text);
                },
                validateGenericNumber(number) {
                    let re = /\d{1,15}/;
                    return re.test(number);
                },
                validateGenericPhoneNumber(number) {
                    let re = /\d{9,15}/;
                    return re.test(number);
                },
                validateName(name) {
                    let re = /^[a-zA-Z\D]{1,60}$/
                    return re.test(name);
                },
                validsortcode(sortcode) {
                    let re = /\d{6}/;
                    return re.test(sortcode);
                },
                validaccountnumber(accountnumber) {
                    let re = /\d{8}$/;
                    return re.test(accountnumber);
                },
                validatePostcode(postcode) {
                    //force space in postcode
                    let re = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s[0-9][A-Za-z]{2})/;
                    return re.test(postcode);
                },
                validateIrPostcode(postcode) {

                    let re = /^([AC-FHKNPRTV-Y]\d{2}|D6W)\s[0-9AC-FHKNPRTV-Y]{4}$/;
                    return re.test(postcode);
                },

                validateURL(URL) {
                    let re = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
                    return re.test(URL);
                },
                validEmail(email) {
                    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(email);
                },
                scrollToErrors() {
                    let headerHeight = $('#header').height();
                    $('html, body').animate({
                        scrollTop: $("#sign_up_form_two").offset().top - headerHeight
                    }, 1000);
                }
            }
        });
    }
});
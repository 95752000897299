jQuery(document).ready(function ($) {
    if ($('#event_listings').length) {
        var app = new Vue({
            el: '#event_listings',
            data: {
                events: [],
                date: '',
                listView: true,
                calendarView: false,
            },
            created() {
                this.setDate();
                this.getEvents();
            },
            methods: {
                setDate() {
                    this.date = new Date().getFullYear();
                    // console.log(this.date);
                },
                getEvents() {
                    $.ajax({
                        type: 'GET',
                        url: '/wp-json/custom-routes/v1/events/' + this.date,
                        beforeSend = (xhr) => {
                            xhr.setRequestHeader('X-WP-Nonce', wpApiSettings.nonce);
                        },
                        error = (res) => {
                            console.log(res);
                        },
                        success = (res) => {
                            // console.log(res);
                            this.events = res;
                        }
                    });
                },
                increaseYear() {
                    this.date++;
                    this.getEvents();
                    // listTemplate = '' +
                    //     '<div class="list_body" id="list-body">' +
                    //     '<div v-for="(month, index) in formattedEvents" v-if="month.length" v-bind:class="[\'event_month\', {current : index === currentMonth}]">' +
                    //     '<h2>{{ monthArray[index] }}</h2>' +
                    //     '<div v-for="post in month" class="event">' +
                    //     '<div class="feat_img">' +
                    //     '<img loading="lazy" v-bind:src="post.feat_image" alt="Event Featured Image">' +
                    //     '</div>' +
                    //     '<div class="titles">' +
                    //     '<p>{{ post.title }}</p>' +
                    //     '</div>' +
                    //     ' <div class="date">' +
                    //     '<p>When</p>' +
                    //     '<p>{{ post.event_date }}</p>' +
                    //     '</div>' +
                    //     '<div class="location">' +
                    //     '<p>Where</p>' +
                    //     '<p>{{ post.event_location }}</p>' +
                    //     '</div>' +
                    //     '<div class="speaker">' +
                    //     '<p>Speaker</p>' +
                    //     '<p>{{ post.event_speaker }}</p>' +
                    //     '</div>' +
                    //     '<a v-bind:href="post.permalink" class="link">Read More</a>' +
                    //     '</div>' +
                    //     '</div>' +
                    //     '<div v-else' +
                    //     '<h2 class="dark-grey">No events found</h2>' +
                    //     '</div>' +
                    //     '</div>';
                },
                decreaseYear() {
                    this.date--;
                    this.getEvents();
                    // listTemplate = '' +
                    //     '<div class="list_body" id="list-body">' +
                    //     '<div v-for="(month, index) in formattedEvents" v-if="month.length" v-bind:class="[\'event_month\', {current : index === currentMonth}]">' +
                    //     '<h2>{{ monthArray[index] }}</h2>' +
                    //     '<div v-for="post in month" class="event">' +
                    //     '<div class="feat_img">' +
                    //     '<img loading="lazy" v-bind:src="post.feat_image" alt="Event Featured Image">' +
                    //     '</div>' +
                    //     '<div class="titles">' +
                    //     '<p>{{ post.title }}</p>' +
                    //     '</div>' +
                    //     ' <div class="date">' +
                    //     '<p>When</p>' +
                    //     '<p>{{ post.event_date }}</p>' +
                    //     '</div>' +
                    //     '<div class="location">' +
                    //     '<p>Where</p>' +
                    //     '<p>{{ post.event_location }}</p>' +
                    //     '</div>' +
                    //     '<div class="speaker">' +
                    //     '<p>Speaker</p>' +
                    //     '<p>{{ post.event_speaker }}</p>' +
                    //     '</div>' +
                    //     '<a v-bind:href="post.permalink" class="link">Read More</a>' +
                    //     '</div>' +
                    //     '</div>' +
                    //     '<div v-else' +
                    //     '<h2 class="dark-grey">No events found</h2>' +
                    //     '</div>' +
                    //     '</div>';
                },
                showListView() {
                    this.listView = true;
                    this.calendarView = false;
                },
                showCalendarView() {
                    this.listView = false;
                    this.calendarView = true;
                },

                
            }
        });
    }


    jQuery(document).ready(function ($) {
        if ($('#podcast_listings').length) {
            var app = new Vue({
                el: '#podcast_listings',
                data: {
                    events: [],
                    date: '',
                    listView: true,
                    calendarView: false,
                },
                created() {
                    this.setDate();
                    this.getEvents();
                },
                methods: {
                    setDate() {
                        this.date = new Date().getFullYear();
                    },
                    getEvents() {
                        $.ajax({
                            type: 'GET',
                            url: '/wp-json/custom-routes/v1/events/' + this.date + '/podcast',
                            error = (res) => {
                                console.log(res);
                            },
                            success = (res) => {
                                this.events = res;
                            }
                        });
                    },
                    increaseYear() {
                        this.date++;
                        this.getEvents();
                    },
                    decreaseYear() {
                        this.date--;
                        this.getEvents();
                    },
                    showListView() {
                        this.listView = true;
                        this.calendarView = false;
                    },
                    showCalendarView() {
                        this.listView = false;
                        this.calendarView = true;
                    }
                }
            });
        }
    });

    

    let listTemplate = '' +
        '<div class="list_body" id="list-body">' +
        '<div v-for="(month, index) in formattedEvents" v-if="month.length" v-bind:class="[\'event_month\', {current : index === currentMonth}]">' +
        '<h2>{{ monthArray[index] }}</h2>' +
        '<div v-for="post in month" class="event">' +
        '<div class="feat_img">' +
        '<img loading="lazy" v-bind:src="post.feat_image ? post.feat_image : \'https://www.mgaa.co.uk/wp-content/uploads/2023/09/mgaa_4_Col_Hi_Res.jpg\'" alt="Event Featured Image">' +
        '</div>' +
        '<div class="titles">' +
        '<p>{{ post.title }}</p>' +
        '<p>{{ post.event_speaker }}</p>' +
        '</div>' +
        ' <div class="date">' +
        '<p>When</p>' +
        '<p>{{ post.event_date }}</p>' +
        '</div>' +
        '<div class="location">' +
        '<p>Where</p>' +
        '<p>{{ post.event_location }}</p>' +
        '</div>' +
        '<a v-bind:href="post.permalink" class="link">Read&nbsp;More</a>' +
        '</div>' +
        '</div>' +
        '<div v-else>' +
        // '<p class="dark-grey">No more events found</p>' +
        '</div>' +
        '</div>';

    Vue.component('events-list-view', {
        template: listTemplate,
        props: {
            events: Array
        },
        data() {
            return {
                monthArray: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                currentMonth: new Date().getMonth(),
            };
        },
        // created() {
        //     console.log(this.events);
        // },
        mounted() {
            // console.log('mounted');

            this.scrollToCurrentMonth();
        },
        computed: {
            formattedEvents: function () {
                const formattedEvents = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    []
                ];

                this.events.forEach(function (post) {
                    var dateAr = post.event_date.split('/');
                    var newDateFormat = dateAr[2] + '/' + dateAr[1] + '/' + dateAr[0];
                    let month = new Date(newDateFormat).getMonth();
                    formattedEvents[month].push(post);
                });

                return formattedEvents;
            }
        },
        methods: {
            scrollToCurrentMonth() {
                $('#event_listings .list_body').animate({
                    scrollTop: $(".list_body .current").offset().top
                }, 2000);
            }
        }
    });

    // Vue.component('events-calendar-view', {
    //     template: listTemplate,
    //     props: {
    //         events: Array
    //     },
    //     data() {
    //         return {
    //             monthArray:  ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    //         };
    //     },
    //     computed: {
    //         formattedEvents: function() {
    //             const formattedEvents = [[],[],[],[],[],[],[],[],[],[],[],[]];

    //             this.events.forEach(function(post) {
    //                 let month = new Date(post.event_date).getMonth();
    //                 formattedEvents[month].push(post)
    //             });

    //             return formattedEvents;
    //         }
    //     }
    // });

    //////////////////////////////////////////////////////////////////////
    // EVENT SIGN UP FUNCTIONALITY 
    /////////////////////////////////////////////////////////////////////

    $('#company-member-signup button').one('click', function() {
        $(this).prop('disabled', true); // Disable the button
        
        let userId = $('#company-member-select').val();
        let attendOnline = $(this).data('attend-online');
        let eventId = $('#event_id').val();

        $.ajax({
            url: ajax_url,
            type: 'post',
            data: {
                eventId: eventId,
                userId: userId,
                attendOnline: attendOnline,
                action: 'signMemberUpToEvent'
            },
            error: function (response) {
                console.log(response);
            },
            success: function (response) {
                // console.log(response);
                location.reload();
            }
        });
    });

    $('#user-event-signup button').one('click', function() {
        $(this).prop('disabled', true); // Disable the button

        let attendOnline = $(this).data('attend-online');
        let eventId = $('#event_id').val();

        $.ajax({
            url: ajax_url,
            type: 'post',
            data: {
                eventId: eventId,
                attendOnline: attendOnline,
                action: 'userSignUpToEvent'
            },
            error: function (response) {
                console.log(response);
            },
            success: function (response) {
                // console.log(response);
                location.reload();
            }
        });
    });

    $('#unattend-event').on('click', function() {
        let eventId = $('#event_id').val();

        $.ajax({
            url: ajax_url,
            type: 'post',
            data: {
                eventId: eventId,
                action: 'unattendEvent'
            },
            error: function (response) {
                console.log(response);
            },
            success: function (response) {
                // console.log(response);
                location.reload();
            }
        });
    });
});
jQuery(document).ready(function ($) {
    if ($('#user_pass_form').length) {
        var app = new Vue({
            el: '#user_pass_form',
            data: {
                form: {
                    curPass: '',
                    pass: '',
                    passConfirm: '',
                },
                error: {
                    msg: [],
                    status: []
                },
                passError: false,
                passConfirmError: false,
                response: ''
            },
            methods: {
                updatePassData() {
                    this.validateForm();

                    if (this.error.msg.length === 0) {
                        $.ajax({
                            url: ajax_url,
                            type: 'POST',
                            // dataType: 'json',
                            data: {
                                curPass: this.form.curPass,
                                password: this.form.pass,
                                action: 'update_user_pass_settings'
                            },
                            error: function (res) {
                                console.log(res);
                            },
                            success = (res) => {
                                console.log(res);
                                this.response = res;
                            }
                        });
                    } else {
                        this.scrollToErrors();
                    }
                },
                validateForm() {
                    this.error.msg = [];
                    this.error.status = {};

                    if (!this.form.curPass) {
                        this.error.msg.push('Current Password field required.');
                        this.error.status['curPass'] = true;
                    }

                    if (!this.form.pass || !this.form.confirmPass) {
                        this.error.msg.push('New Password fields required.');
                        this.error.status['pass'] = true;
                    } else {
                        let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

                        if (!re.test(this.form.pass)) {
                            this.error.status['pass'] = true;
                            this.error.msg.push('New Passwords must include one uppercase, one lowercase, one digit and at least 8 characters.');
                        } else if (this.form.pass !== this.form.confirmPass) {
                            this.error.msg.push('New Passwords must match.');
                            this.error.status['pass'] = true;
                        }
                    }

                },
                scrollToErrors() {
                    let headerHeight = $('#header').height();
                    $('html, body').animate({
                        scrollTop: $("#user_pass_form").offset().top - headerHeight
                    }, 1000);
                },
                passValidation() {
                    this.passError = false;
                    let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

                    if (this.form.pass) {
                        if (!re.test(this.form.pass)) {
                            this.passError = true;
                        }
                    }
                },
                confirmPassValidation() {
                    this.passConfirmError = false;

                    if (this.form.pass !== this.form.confirmPass) {
                        this.passConfirmError = true;
                    }
                },
                switchInputFocus() {
                    if (!this.form.pass) {
                        this.$refs.pass.focus();
                    }
                }
            }
        });
    }
});
jQuery(document).ready(function ($) {
   $('#gocardless-add').on('click', function () {
      $.ajax({
          url: ajax_url,
          type: 'POST',
          dataType: 'json',
          data: {
              action: 'sendgocardlessapicall'
          },
          error: function (res) {
              console.log(res);
          },
          success: function (res) {
              console.log(res);
              // if (res.success === 'false') {
              //     $('#gocardless-add-error').text('Error: Check that a Billing Personnel User is set or that a Payment Method has been selected.');
              // }
              // if (res.success === 'true') {
              //     location.reload();
              // }
          }
      });
  });
  $('#gocardless-add-bank').on('click', function () {
   console.log('click');

   $.ajax({
       url: ajax_url,
       type: 'POST',
       dataType: 'json',
       data: {
           action: 'sendgocardlessbankapicall'
       },
       error: function (res) {
           console.log(res);
       },
       success: function (res) {
           console.log(res);
           // if (res.success === 'false') {
           //     $('#gocardless-add-error').text('Error: Check that a Billing Personnel User is set or that a Payment Method has been selected.');
           // }
           // if (res.success === 'true') {
           //     location.reload();
           // }
       }
   });
});
   var gocardlessHandler = function() {
      var gocardlessClient = GoCardlessClient({
         token: "PAT_eUPwI1BBBO-dRg3hgK_xeigso_6EB9Dcl64eUIpO",
         
      });

      gocardlessClient.create({
         onSave: function(payerAuthorisationId, confirmCallback, metadata) {
            // 1. Optional: store the payerAuthorisationId server-side
            // 2. Mandatory: call confirmCallback to let the Drop-in flow continue
            /* Note: If storing the payerAuthorisationId on server-side, call confirmCallback only after it is saved. And you should create payments, subscriptions etc once you receive the payer_authorizations completed webhook on the server side. */
            confirmCallback();
         },
         onComplete: function(metadata) {
            // The customer completed successfully
            alert("Drop-in flow completed!");
         },
         onExit: function(error, metadata) {
            if (error === undefined) {
               // The customer left intentionally the Drop-in flow
               // (for example they closed the modal interface).
            } else {
               // The customer left due to unrecoverable error.
            }
         }
      });
   };

   $('#pay-with-gocardless-button').click(function(){
      console.log('ckick');
      gocardlessHandler();
   });
});
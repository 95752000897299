jQuery(document).ready(function ($) {
    if ($('#user_settings_form').length) {
        var app = new Vue({
            el: '#user_settings_form',
            data: {
                form: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    pass: '',
                    passConfirm: '',
                    position: '',
                    addressLineOne: '',
                    addressLineTwo: '',
                    addressLineThree: '',
                    town: '',
                    county: '',
                    postcode: '',
                    country: '',
                    personalLines:'',
                    claimsForum: '',
                    financeContact: '',
                    nextGen: '',
                    riskManagement: '',
                    underwriting: '',
                    mgaMarketingContact: '',
                    mgaLearningDevelopment: '',
                    complianceForumContact: '',
                    eventsTraining: '',
                    insurerContact: '',
                    itOperationsForum: '',
                    brexitForum: '',
                    londonMarketForum: '',
                },
                error: {
                    msg: [],
                    status: []
                },
                passError: false,
                passConfirmError: false,
                mgaMember: false,
            },
            created() {
                this.getUserData();
            },
            methods: {
                getUserData() {
                    $.ajax({
                        url: ajax_url,
                        type: 'GET',
                        dataType: 'json',
                        data: {
                            action: 'user_settings_data'
                        },
                        error: function (res) {
                            console.log(res);
                        },
                        success: (res) => {
                            console.log(res);
                            this.form = res.form;
                            this.mgaMember = res.mgaMember;
                        }
                    });
                },
                updateUserData() {
                    $('#update-msg').hide();
                    this.validateForm();
                    if (this.error.msg.length === 0) {
                        $.ajax({
                            url: ajax_url,
                            type: 'POST',
                            dataType: 'json',
                            data: {
                                formData: this.form,
                                action: 'update_user_settings'
                            },
                            error: function (res) {
                                console.log(res);
                            },
                            success: (res) => {
                                console.log(res);
                                if (res.email_updated === '1') {
                                    window.location.href = 'https://www.mgaa.co.uk/login/';
                                } else {
                                    $('#update-msg').show();
                                }
                            }
                        });
                    } else {
                        this.scrollToErrors();
                    }
                },
                validateForm() {
                    this.error.msg = [];
                    this.error.status = {};

                    if (!this.form.firstName) {
                        this.error.msg.push('First Name required.');
                        this.error.status['firstName'] = true;
                    }

                    if (!this.form.lastName) {
                        this.error.msg.push('Last Name required.');
                        this.error.status['lastName'] = true;
                    }

                    if (!this.form.position) {
                        this.error.msg.push('Position required.');
                        this.error.status['position'] = true;
                    }

                    if (!this.form.email) {
                        this.error.msg.push('Email required.');
                        this.error.status['email'] = true;
                    } else if (!this.validEmail(this.form.email)) {
                        this.error.msg.push('Valid email required.');
                        this.error.status['email'] = true;
                    }

                    // if (!this.form.mobile) {
                    //     this.error.msg.push('Mobile Number required.');
                    //     this.error.status['mobile'] = true;
                    // }

                    // if (!this.form.pass || !this.form.confirmPass) {
                    //     this.error.msg.push('Password fields required.');
                    //     this.error.status['pass'] = true;
                    // } else {
                    //     let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

                    //     if (!re.test(this.form.pass)) {
                    //         this.error.status['pass'] = true;
                    //         this.error.msg.push('Passwords must include one uppercase, one lowercase, one digit and at least 8 characters.');
                    //     } else if (this.form.pass !== this.form.confirmPass) { 
                    //         this.error.msg.push('Passwords must match.');
                    //         this.error.status['pass'] = true;
                    //     }
                    // }

                    // if (!this.form.addressLineOne) {
                    //     this.error.msg.push('Address Line One required.');
                    //     this.error.status['addLineOne'] = true;
                    // }

                    // if (!this.form.town) {
                    //     this.error.msg.push('Town required.');
                    //     this.error.status['town'] = true;
                    // }

                    // if (!this.form.county) {
                    //     this.error.msg.push('County required.');
                    //     this.error.status['county'] = true;
                    // }

                    // if (!this.form.postcode) {
                    //     this.error.msg.push('Postcode required.');
                    //     this.error.status['postcode'] = true;
                    // }

                    // if (!this.form.country) {
                    //     this.error.msg.push('Country required.');
                    //     this.error.status['country'] = true;
                    // }
                },
                scrollToErrors() {
                    let headerHeight = $('#header').height();
                    $('html, body').animate({
                        scrollTop: $("#user_settings_form").offset().top - headerHeight
                    }, 1000);
                },
                validEmail(email) {
                    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(email);
                },
                passValidation() {
                    this.passError = false;
                    let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

                    if (this.form.pass) {
                        if (!re.test(this.form.pass)) {
                            this.passError = true;
                        }
                    }
                },
                confirmPassValidation() {
                    this.passConfirmError = false;

                    if (this.form.pass !== this.form.confirmPass) {
                        this.passConfirmError = true;
                    }
                },
                switchInputFocus() {
                    if (!this.form.pass) {
                        this.$refs.pass.focus();
                    }
                }
            }
        });
    }
});
jQuery(document).ready(function ($) {
    if ($('#product_finder_page').length) {
        var app = new Vue({
            el: '#product_finder',
            data: {
                rawCompanies: [],
                companies: [],
                productOptions: [],
                searchString: '',
                suggest: false
            },
            computed: {
                options: function () {
                    const filteredOptions = new Array();

                    for (let x = 0; x < this.productOptions.length; x++) {
                        if (this.productOptions[x].toLowerCase().includes(this.searchString.toLowerCase())) {
                            filteredOptions.push(this.productOptions[x]);
                        }
                    }

                    return filteredOptions;
                }
            },
            created() {
                this.getData();
                let brokerSearch = sessionStorage.getItem('brokerSearch');
                this.searchString = brokerSearch;
            },
            methods: {
                getData() {
                    $.ajax({
                        url: '/wp-json/custom-routes/v1/members/mga_member',
                        error:(res) => {
                            console.log(res);
                        },
                        success:(res) => {
                            this.rawCompanies = res;
                            this.companies = res;
                            this.filterCompanies();

                        }
                    });

                    $.ajax({
                        url: '/wp-json/custom-routes/v1/product-types',
                        error:(res) => {
                            console.log(res);
                        },
                        success:(res) => {
                            // this.productOptions = res;
                            this.productOptions = res.map(function (item) {
                                return item.product_name;
                            });
                        }
                    });
                },
                clear() {
                    this.searchString = "";
                    this.companies = this.rawCompanies;
                    sessionStorage.setItem('brokerSearch', "");

                },
                filterCompanies() {
                    // hide suggestions as search as user has searched
                    this.suggest = false;
                    // if search string has a length > than 1 further filter by company name 
                    if (this.searchString.length > 1) {
                        // filter raw company data
                        let filteredCompanies = this.rawCompanies.filter(company => {
                            // loop through products array inside company
                            for (let x = 0; x < company.products.length; x++) {
                                // if any of the product values match the search return the company object
                                if (company.products[x].toLowerCase() === this.searchString.toLowerCase()) {
                                    return company;
                                }
                            }
                        });

                        this.companies = filteredCompanies;
                    }else{
                    this.companies = this.rawCompanies;
                    }
                },
                selectOption(option) {
                    this.searchString = option;
                    sessionStorage.setItem('brokerSearch', option);
                    this.filterCompanies();
                },
                keyActions(event) {
                    let key = event.keyCode;
                    let index = 0; // default select first option
                    let option = $('.option');
                    let activeOption = option.hasClass('active') ? true : false;

                    //if key pressed 40 = down, 38 = up, 13 = enter
                    if (key === 40) {
                        // if there is an action option select next else index = 0
                        if (activeOption) {
                            index = $('.option.active').index() + 1;
                        }
                        option.removeClass('active');
                        option.eq(index).addClass('active');
                    } else if (key === 38) {
                        // if there is an action option select previous else index = 0
                        if (activeOption) {
                            index = $('.option.active').index() - 1;
                        }
                        option.removeClass('active');
                        option.eq(index).addClass('active');
                    } else if (key === 13) {
                        // if active option set searchString value else filter by current input
                        if (activeOption) {
                            this.selectOption($('.option.active').text().trim());
                        } else {
                            this.filterCompanies();
                        }
                    }
                }
            }
        });
    }

    // $('#broker-mailing-button').click(function() {
    //     let email = $('input[name=email-address-broker]').val();
    //     let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    //     if (re.test(email)) {
    //         $.ajax({
    //             url: ajax_url,
    //             type: 'POST',
    //             data: {
    //                 email: email,
    //                 action: 'broker_mailing_list_signup'
    //             },
    //             error: function (res) {
    //                 console.log(res);
    //             },
    //             success: function (res) {
    //                 $('.sidebar .res').text(res);
    //             }
    //         });
    //     } else {
    //         $('.sidebar .res').text("Please enter a valid email address.");
    //     }
    // });
});
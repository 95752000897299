//changes gor eur
jQuery(document).ready(function ($) {
    let country = localStorage.getItem("location");
    if(country){
        $('#country').val(country);
    }
    $('#country').change(function () {
        country = $(this).val();
        localStorage.setItem("location",country)
        updatecountry();
    });
    function updatecountry(){
        if (country == "IR"){
            $('.priceire').removeClass("displaynone");
            $('.priceuk').addClass("displaynone");
        }else{
            $('.priceire').addClass("displaynone");
            $('.priceuk').removeClass("displaynone");
        }
    }
    updatecountry();
})

jQuery(document).ready(function ($) {
    if ($('#dashboard-billing-form').length) {
        var app = new Vue({
            el: '#dashboard-billing-form',
            data: {
                form: {},
                errors: {
                    msg: [],
                    bool: {}
                },
                currentDate = new Date(),
                monthly: currentDate.getMonth(),
            },
            created() {
                this.getPreFilledData();
                if (this.monthly < 6 || this.monthly > 7) {
                    $("#patment-select").html(`<option value="">Select a Payment Method</option>
                    <option value="monthly">Monthly - Direct Debit</option>
                    <option value="annually">Annually - BAC's</option>
                    <option value="annually-card">Annually - Card</option>`)
                }
            },
            methods: {
                getPreFilledData() {
                    $.ajax({
                        url: ajax_url,
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            action: 'getBillingData'
                        },
                        error: function (res) {
                            console.log(res);
                        },
                        success = (res) => {
                            console.log(res);
                            this.form = res;
                        }
                    });
                },
                submitForm() {
                    this.validateForm();

                    if (this.errors.msg.length <= 0) {
                        $.ajax({
                            url: ajax_url,
                            type: 'POST',
                            // dataType: 'json',
                            data: {
                                formData: this.form,
                                action: 'updateBillingData'
                            },
                            error: function (res) {
                                console.log(res);
                            },
                            success: function (res) {
                                // console.log(res);
                                location.reload();
                            }
                        });
                    }
                },
                validateForm() {
                    this.errors.msg = [];
                    this.errors.bool = {};

                    if (this.form.diffBillingAddress === '1') {
                        if (!this.form.billingAddressLineOne) {
                            this.errors.msg.push('Billing Address Line 1 required.');
                            this.errors.bool['billingAddressLineOne'] = true;
                        }

                        if (!this.form.billingTown) {
                            this.errors.msg.push('Billing Address Town required.');
                            this.errors.bool['billingTown'] = true;
                        }

                        if (!this.form.billingCounty) {
                            this.errors.msg.push('Billing Address County required.');
                            this.errors.bool['billingCounty'] = true;
                        }

                        if (!this.form.billingPostcode) {
                            this.errors.msg.push('Billing Address Postcode required.');
                            this.errors.bool['billingPostcode'] = true;
                        }

                        if (!this.form.billingCountry) {
                            this.errors.msg.push('Billing Address Country Name required.');
                            this.errors.bool['billingCountry'] = true;
                        }
                    }

                    if (this.form.paymentMethod === '') {
                        this.errors.msg.push('Billing Payment Method required.');
                        this.errors.bool['paymentMethod'] = true;
                    }
                    if (this.form.paymentMethod == 'monthly') {

                        this.form.sortcode = this.form.sortcode.replace(/[^0-9]/g, '');
                        if (!this.validsortcode(this.form.sortcode)) {
                            if (this.form.paymentMethod == 'monthly' && this.form.sortcode != "") {
                                this.errors.msg.push('SortCode is required for Direct Debit Payments.');
                                this.errors.bool['sortcode'] = true;
                            }
                        }

                        if (!this.validaccountnumber(this.form.accountnumber)) {
                            this.form.accountnumber = this.form.accountnumber.replace(/[^0-9]/g, '')
                            if (this.form.accountnumber != "") {
                                this.errors.msg.push('Account Number is required for Direct Debit Payments.');
                                this.errors.bool['accountnumber'] = true;
                            }
                        }
                    }
                },
                
                validsortcode(sortcode) {
                    let re = /\d{6}/;
                    return re.test(sortcode);
                },
                validaccountnumber(accountnumber) {
                    let re = /\d{8}$/;
                    return re.test(accountnumber);
                },
            },
            computed: {
                billingCountry: function () {
                    if (this.form.paymentcountry) {
                        if (this.form.paymentcountry == 'IR') {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return false;
                }
            }
        });
    }
});
jQuery(document).ready(function ($) {

    let thispriceire1 = $('.datadiv').data('priceire1');
    let thispriceire2 = $('.datadiv').data('priceire2');
    let thispriceire3 = $('.datadiv').data('priceire3');
    let thispriceire4 = $('.datadiv').data('priceire4');
    let thispriceire5 = $('.datadiv').data('priceire5');
    let thispriceire6 = $('.datadiv').data('priceire6');
    let thispriceire7 = $('.datadiv').data('priceire7');
    let thisprice1 = $('.datadiv').data('price1');
    let thisprice2 = $('.datadiv').data('price2');
    let thisprice3 = $('.datadiv').data('price3');
    let thisprice4 = $('.datadiv').data('price4');
    let thisprice5 = $('.datadiv').data('price5');
    let thisprice6 = $('.datadiv').data('price6');
    let thisprice7 = $('.datadiv').data('price7');
    let thismarpriceire = $('.datadiv').data('marpriceire');
    let thismarpriceuk = $('.datadiv').data('marpriceuk');
    let thismarjoinpriceire = $('.datadiv').data('marjoinpriceire');
    let thismarjoinpriceuk = $('.datadiv').data('marjoinpriceuk');
    let thissuppriceire = $('.datadiv').data('suppriceire');
    let thissuppriceuk = $('.datadiv').data('suppriceuk');
    let thissupjoinpriceire = $('.datadiv').data('supjoinpriceire');
    let thissupjoinpriceuk = $('.datadiv').data('supjoinpriceuk');

    if ($('#sign_up_form_one').length) {

        var app = new Vue({
            el: '#sign_up_form_one',
            data: {
                membershipType: '',
                gwp: '',
                firstName: '',
                lastName: '',
                company: '',
                email: '',
                pass: '',
                confirmPass: '',
                termsConditions: '0',
                reason: '',
                payment_country: '',
                error: {
                    msg: [],
                    status: {}
                },
                passError: false,
                passConfirmError: false
            },
            computed: {
                joiningFee: function () {
                    if (this.payment_country == 'IR') {
                        if (this.membershipType == 'MGA Member') {
                            return '€0';
                        } else if (this.membershipType == 'Market Practitioner') {
                            return '€' + thismarjoinpriceire;
                        } else if (this.membershipType == 'Supplier') {
                            return '€' + thissupjoinpriceire;
                        }
                    } else {
                        if (this.membershipType == 'MGA Member') {
                            return '£0';
                        } else if (this.membershipType == 'Market Practitioner') {
                            return '£' + thismarjoinpriceuk;
                        } else if (this.membershipType == 'Supplier') {
                            return '£' + thissupjoinpriceuk;
                        }
                    }
                },
                perAnnumFee: function () {
                    let thisPrice = this;
                    if (this.payment_country == 'IR') {
                        if (this.membershipType == 'MGA Member') {
                            switch (this.gwp) {
                                case 'Under £2.5m GWP':
                                    return '€' + thispriceire1;
                                case 'Under £20m GWP':
                                    return '€' + thispriceire2;
                                case 'Under £50m GWP':
                                    return '€' + thispriceire3;
                                case 'Under £100m GWP':
                                    return '€' + thispriceire4;
                                case 'Under £200m GWP':
                                    return '€' + thispriceire5;
                                case 'Under £500m GWP':
                                    return '€' + thispriceire6;
                                case 'Over £500m GWP':
                                    return '€' + thispriceire7;
                            }
                        } else if (this.membershipType == 'Market Practitioner') {
                            return '€' + thismarpriceire;
                        } else if (this.membershipType == 'Supplier') {
                            return '€' + thissuppriceire;
                        }
                    } else {
                        if (this.membershipType == 'MGA Member') {
                            switch (this.gwp) {
                                case 'Up to £2.5m GWP':
                                    return '£' + thisprice1;
                                case 'Up to £20m GWP':
                                    return '£' + thisprice2;
                                case 'Up to £50m GWP':
                                    return '£' + thisprice3;
                                case 'Up to £100m GWP':
                                    return '£' + thisprice4;
                                case 'Up to £200m GWP':
                                    return '£' + thisprice5;
                                case 'Up to £500m GWP':
                                    return '£' + thisprice6;
                                case '£500m GWP and above':
                                    return '£' + thisprice7;
                            }
                        } else if (this.membershipType == 'Market Practitioner') {
                            return '£' + thismarpriceuk;
                        } else if (this.membershipType == 'Supplier') {
                            return '£' + thissuppriceuk;
                        }
                    }
                },
            },
            created() {
                if (localStorage.getItem('membership_type')) {
                    this.membershipType = localStorage.getItem('membership_type');
                }
                if (localStorage.getItem('location')) {
                    this.payment_country = localStorage.getItem('location');
                }

                if (localStorage.getItem('gwp')) {
                    this.gwp = localStorage.getItem('gwp');
                }
            },
            methods: {
                signUp() {
                    this.validateForm();

                    if (this.error.msg.length === 0) {
                        let data = {
                            "membershipType": this.membershipType,
                            "gwp": this.gwp,
                            "firstName": this.firstName,
                            "lastName": this.lastName,
                            "company": this.company,
                            "email": this.email,
                            "password": this.pass,
                            "termsConditions": this.termsConditions,
                            "reason": this.reason,
                            "payment_country": this.payment_country,
                        };

                        $.ajax({
                            url: ajax_url,
                            type: 'POST',
                            dataType: 'json',
                            data: {
                                formData: data,
                                action: 'UserSignUp'
                            },
                            error: function (res) {
                                console.log(res);
                            },
                            success = (res) => {
                                if (res.successful === 'true') {
                                    window.location.href = res.redirect;
                                } else {
                                    // set email already exists error
                                    this.error.msg.push(res.error);
                                    this.scrollToErrors();
                                }
                            }
                        });
                    } else {
                        this.scrollToErrors();
                    }
                },
                validateForm() {
                    this.error.msg = [];
                    this.error.status = {};

                    if (!this.membershipType) {
                        this.error.msg.push('Membership Type required.');
                        this.error.status['membershipType'] = true;
                    } else {
                        if (this.membershipType === 'MGA Member') {
                            if (!this.gwp) {
                                this.error.msg.push('Per annum required.');
                                this.error.status['gwp'] = true;
                            }
                        }
                    }
                    if (!this.firstName) {
                        this.error.msg.push('First Name required.');
                        this.error.status['firstName'] = true;
                    } else if (!this.validateName(this.firstName)) {
                        this.error.msg.push('First Name must only contain letters');
                        this.error.status['firstName'] = true;
                    }


                    if (!this.lastName) {
                        this.error.msg.push('Last Name required.');
                        this.error.status['lastName'] = true;
                    } else if (!this.validateName(this.lastName)) {
                        this.error.msg.push('Last Name must only contain letters');
                        this.error.status['lastName'] = true;
                    }

                    if (!this.company) {
                        this.error.msg.push('Company Name required.');
                        this.error.status['company'] = true;
                    } else if (!this.validatecompany(this.company)) {
                        this.error.msg.push('Please enter a valid company name');
                        this.error.status['company'] = true;
                    }

                    if (!this.email) {
                        this.error.msg.push('Email required.');
                        this.error.status['email'] = true;
                    } else if (!this.validEmail(this.email)) {
                        this.error.msg.push('Valid email required.');
                        this.error.status['email'] = true;
                    }

                    if (!this.pass || !this.confirmPass) {
                        this.error.msg.push('Password fields required.');
                        this.error.status['pass'] = true;
                    } else {
                        let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

                        if (!re.test(this.pass)) {
                            this.error.status['pass'] = true;
                            this.error.msg.push('Passwords must include one uppercase, one lowercase, one digit and at least 8 characters.');
                        }
                        if (this.pass !== this.confirmPass) {
                            this.error.msg.push('Passwords must match.');
                            this.error.status['pass'] = true;
                        }
                    }

                    if (this.termsConditions === '0' && !this.reason) {
                        this.error.msg.push('Please give a reason for not accepting the terms and conditions.');
                        this.error.status['reason'] = true;
                    }
                },
                validateName(name) {
                    let re = /^[a-zA-Z\D]{1,60}$/
                    return re.test(name);
                },
                validatecompany(company) {
                    let re = /^[a-zA-Z0-9\s\S\D]{1,60}$/
                    return re.test(company);
                },
                
                scrollToErrors() {
                    let headerHeight = $('#header').height();
                    $('html, body').animate({
                        scrollTop: $("#form").offset().top - headerHeight
                    }, 1000);
                },
                validEmail(email) {
                    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(email);
                },
                passValidation() {
                    this.passError = false;
                    let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

                    if (this.pass) {
                        if (!re.test(this.pass)) {
                            this.passError = true;
                        }
                    }
                },
                confirmPassValidation() {
                    this.passConfirmError = false;

                    if (this.pass !== this.confirmPass) {
                        this.passConfirmError = true;
                    }
                },
                switchInputFocus() {
                    if (!this.pass) {
                        this.$refs.pass.focus();
                    }
                }
            }
        })
    }
});
jQuery(document).ready(function ($) {
    setTimeout(function () {
        $(".circles").addClass("grow");
    }, 500);

    // Add smooth scrolling to all links
    $(".award-category").on("click", function (event) {
        // Make sure this.hash has a value before overriding default behavior
        if (this.hash !== "") {
            // Prevent default anchor click behavior
            event.preventDefault();

            // Store hash
            var hash = this.hash;

            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $("html, body").animate(
                {
                    scrollTop: $(hash).offset().top - 200
                },
                800
            );
        } // End if
    });

    // Add smooth scrolling to all links
    $(".conf-nav").on("click", function (event) {
        // Make sure this.hash has a value before overriding default behavior
        if (this.hash !== "") {
            // Prevent default anchor click behavior
            event.preventDefault();

            // Store hash
            var hash = this.hash;

            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $("html, body").animate(
                {
                    scrollTop: $(hash).offset().top - 200
                },
                800
            );
        } // End if
    });
});

jQuery(document).ready(function ($) {
    var slider = $(".home-slider");
    // console.log(".home-slider")
    slider.slick({
        arrows: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        // autoplay: true,
        // autoplaySpeed: 5000,
        fade: true,
        cssEase: "linear"
    });

    $(".about-slider").slick({
        dots: false,
        infinite: true,
        speed: 1200,
        fade: true,
        cssEase: "linear",
        arrows: false,
        autoplay: true
    });

    var durationList = $(".slide").map(function (index, item) {
        return item.getAttribute("data-time");
    });

    var slideIndex = 0;
    var changeSlide = function (timing) {
        setTimeout(function () {
            if (timing !== 0) {
                slider.slick("slickNext");
            }
            if (slideIndex >= durationList.length) slideIndex = 0;
            changeSlide(durationList[slideIndex++]);
        }, timing);
    };

    // Check if the body has the specified class
    if ($("body").hasClass("page-template-homepage-WIP")) {
        console.warn("%c homepage-WIP => slider stopped", "color: red;");
    } else {
        console.log("%c homepage => slider running", "color: green;");
        changeSlide(0);
    }

    // could do with adding a reset timer to dots click event.

    /* TODO: 28/09/2023, issue with refreshToken and accessToken in XeroAuth class */
    console.log("custom.js connected");
    document.getElementById("make-request").onclick = makeRequest;
    function makeRequest() {
        console.log("makeRequest()");

        fetch("https://www.mgaa.co.uk/wp-content/themes/MGAA_theme/test.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    /*  */
});

jQuery(document).ready(function ($) {
    console.log("running");
    $(".gallery-image-row").magnificPopup({
        delegate: "a", // the selector for gallery item
        type: "image",
        gallery: {
            enabled: true
        }
    });
});

jQuery(document).ready(function ($) {
    $('.black-button').click(function () {
        i = $(this).next()
        if (i.hasClass('show')) {
            i.removeClass('show');
        } else {
            $('.show').removeClass('show');
            i.addClass("show");
        }
    })
});
jQuery(function($) {
    let validationErrors = [];

    $('#broker-form').on('submit', function(e) {
        e.preventDefault();
        $('#broker-form .res').html('');
        validationErrors = [];
        
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let firstName = $.trim($('#broker-form input[name="first-name"]').val());
        let lastName = $.trim($('#broker-form input[name="last-name"]').val());
        let companyName = $.trim($('#broker-form input[name="company-name"]').val());
        let terms = $('#broker-form input[name="tc"]');
        let email = $('input[name=email-address-broker]').val();
        let capacity = $('input[name="capacity"]').is(':checked') ? '1' : '0';
        let products = $('input[name="products"]').is(':checked') ? '1' : '0';
        let events = $('input[name="events"]').is(':checked') ? '1' : '0';
        let termsCons = $('input[name="tc"]').is(':checked') ? '1' : '0';

        if (firstName === '') {
            validationErrors.push('Error: First Name Required');
        }

        if (lastName === '') {
            validationErrors.push('Error: Last Name Required');
        }

        if (companyName === '') {
            validationErrors.push('Error: Company Name Required');
        }

        if (!re.test(email)) {
            validationErrors.push('Error: Enter a Valid Email Address');
        }

        if (!terms.is(':checked')) {
            validationErrors.push('Error: T&C\'s and marketing must be accepted');
        }

        if (validationErrors.length <= 0) {
            $.ajax({
                url: ajax_url,
                type: 'post',
                dataType: 'json',
                data: {
                    firstName: firstName,
                    lastName: lastName,
                    companyName: companyName,
                    email: email,
                    capacity: capacity,
                    products: products,
                    events: events,
                    action: 'broker_mailing_list_signup'
                },
                error: function(res) {
                    console.log(res);
                },
                success: function(res) {
                    if (res.success === 'false') {
                        if (res.error === 'Sorry, that username already exists!') {
                            validationErrors.push('Sorry, that Email already exists!');
                        } else {
                            validationErrors.push(res.error);
                        }

                        display_results();
                    } else if (res.success === 'true') {
                        validationErrors.push('Thank You for signing up to our mailing list.');

                        display_results();
                    }
                }
            });
        } else {
            display_results();
        }
    });

    function display_results() {
        validationErrors.forEach((error) => {
            $('#broker-form .res').append('<li>' + error + '</li>');
        });
    }
});